exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blogs-1-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/dongjoon/Documents/Programming/dongjoon-personal-website/blogs/1.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blogs-1-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blogs-2-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/dongjoon/Documents/Programming/dongjoon-personal-website/blogs/2.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blogs-2-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-gallery-hobby-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/dongjoon/Documents/Programming/dongjoon-personal-website/gallery/hobby.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-gallery-hobby-mdx" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

